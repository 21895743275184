.resume {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 30px;
}

.content {
    width: 100%;
    margin-right: 10%;
    margin-top: 60px;
    margin-bottom: 60px;
}
