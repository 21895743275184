.projects {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 30px;
}

.content {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-right: 10%;
    margin-top: 60px;
    margin-bottom: 60px;
}

.project-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    padding: 25px;
    
    overflow-y: scroll;
    scrollbar-width: none;
}