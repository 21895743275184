.skills {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 30px;
}

.content {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-right: 10%;
    margin-top: 60px;
}

.skills-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    margin-top: 10px;

    overflow-y: scroll;
    scrollbar-width: none;
}

.search {
    position: relative;
    display: flex;
    width: 30%;

    margin-top: 1%;
    margin-bottom: 1%;
}

.search input {
    border: none;
    outline: none;

    transform-origin: left;
}

.underline {
    position: absolute;
    bottom: 0;
    left: 24px;
    height: 2px;
    border-radius: 50px;
    background-color: #000; /* Change to your desired color */
    width: 100%;
}