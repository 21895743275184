.landing {
    display: flex;
    height: 100%;
    gap: 30px;
}

.content {
    display: flex;
    flex-direction: column;

    margin-right: 10%;
    margin-top: 60px;
    
    overflow-y: scroll;
    scrollbar-width: none;
}

.about {
    display: flex;
    gap: 30px;
}

.about .logo {
    height: auto;
    width: 30%;
    flex-shrink: 0;
    aspect-ratio: 1/1;
}

.about .logo img{
    height: 100%;
    width: 100%;
    opacity: 0.8;
}

.about .info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 15px;

    width: auto;
}

.about h2 {
    margin-top: auto;
    font-size: 0.9rem;
    font-weight: 500;
}

.about h3 {
    margin-top: auto;
    font-size: 0.8rem;
    font-weight: 400;
}

.dotted-line {
    width: 100%;
    opacity: 0.3;
    border-bottom: 2px dotted #000;
    margin: 20px 0;

    z-index: -1;
}

.work-grid {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    gap: 20px;
    justify-content: space-around;

    margin-top: 10px;
}

@media (max-width: 1200px) {  
    .logo {
        display: none;
    }

    .recent-work {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}