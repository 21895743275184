.contact {
    display: flex;
    height: 100%;
    width: 100%;
    gap: 30px;
}

.content {
    display: flex;
    flex-direction: column;

    width: 100%;
    margin-right: 10%;
    margin-top: 60px;
}

.contact-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    margin-top: 10px;
}